import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, ErrorMessage } from "formik";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  Grid,
  InputAdornment,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { setAccessToken } from "accessToken";
import { CustomButton, Loader } from "components/Utilities";
import { pageOneUseStyles } from "styles/formStyles";
import { signup } from "components/graphQL/Mutation";
import { signUpInitialState } from "helpers/mockData";
import {
  deleteVar,
  getProviderOptions,
  signUp as onSignUp,
} from "helpers/helperFuncs";
import LoginInput from "components/validation/LoginInput";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SignUpValidationSchema } from "helpers/formValidation";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  fetchUserTypes,
  getPartners,
  getProviders,
} from "components/graphQL/UseQuery";
import { FormikControl } from "components/validation";
import { useStepDispatch } from "context/stepContext";
import { useDataDispatch } from "context/dataContext";
import { consultationsRows } from "components/Utilities/tableData";
import { TextError } from "components/Utilities/TextError";

const SignUpForm = () => {
  const dataDispatch = useDataDispatch();
  const stepDispatch = useStepDispatch();
  const classes = pageOneUseStyles();
  const theme = useTheme();
  const [register] = useMutation(signup);
  const { enqueueSnackbar } = useSnackbar();
  const [getUserTypes] = useLazyQuery(fetchUserTypes);
  const [showPassword, setShowPassword] = useState(false);
  const [affiliationOptions, setAffiliationOptions] = useState([]);
  const [currentAffiliation, setCurrentAffiliation] = useState("");
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [fetchProviders, { loading, variables }] = useLazyQuery(getProviders);

  const greenButton = {
    background: theme.palette.success.main,
    hover: theme.palette.success.light,
    active: theme.palette.primary.dark,
  };

  useEffect(() => {
    getUserTypes()
      .then(({ data }) => {
        const userTypes = data?.getUserTypes?.userType;
        const affiliationValues = userTypes
          .map((type) => {
            if (type?.name === "Heala User") {
              return { key: "Freelance Doctor", value: type?._id };
            }
            if (type?.name === "Hospital Enrollee") {
              return { key: "Hospital Doctor", value: type?._id };
            }
            if (type?.name === "HMO Enrollee") {
              return { key: "HMO Doctor", value: type?._id };
            }
          })
          .filter((selected) => selected);

        setAffiliationOptions(affiliationValues);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onSubmit = async (signUpData, onsubmitProp) => {
    let organizationName = "";
    organizationOptions.map((option) => {
      if (Object.values(option)[0] === signUpData?.providerId) {
        organizationName = Object.values(option)[1];
      }
      return "";
    });

    const { success, data } = await onSignUp(
      register,
      signUpData,
      setAccessToken,
      enqueueSnackbar,
      onsubmitProp
    );
    if (success === true) {
      dataDispatch({
        type: "UPDATE_DATA",
        data: { hospital: organizationName, providerId: data?.providerId },
      });
      stepDispatch({ type: "CHANGE_STEP", step: 2 });
    } else {
      dataDispatch({ type: "CHANGE_STATE", data: {} });
    }
  };

  const onAffiliationHandleChange = async (value, setFieldValue) => {
    setOrganizationOptions([]);
    setFieldValue("providerId", "");
    const id = value?.target?.value;
    setFieldValue("affiliation", id);
    const currentAffiliation = affiliationOptions.filter(
      (option) => option?.value === id
    );
    setCurrentAffiliation(currentAffiliation[0].key);
    let values = [];
    const healaId = affiliationOptions.filter(
      (option) => option.key === "Freelance Doctor"
    );
    const hmoId = affiliationOptions.filter(
      (option) => option.key === "HMO Doctor"
    );
    const hospitalId = affiliationOptions.filter(
      (option) => option.key === "Hospital Doctor"
    );

    const newVaribles =
      id === healaId[0]?.value
        ? { userTypeId: String(id) }
        : id === hmoId[0]?.value
        ? { userTypeId: String(id) }
        : id === hospitalId[0]?.value
        ? { userTypeId: String(id) }
        : null;

    if (newVaribles) {
      deleteVar(variables);
      const opts = await getProviderOptions(
        fetchProviders,
        { ...newVaribles, orderBy: "name", page: -1 } || {
          orderBy: "name",
          page: -1,
        }
      );
      values = opts;
    }

    if (id === healaId[0]?.value) {
      setOrganizationOptions([]);
      setFieldValue("providerId", values[0]?.value);
    } else {
      setOrganizationOptions(values);
    }
  };

  return (
    <Grid item>
      <Formik
        initialValues={signUpInitialState}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={SignUpValidationSchema}
        onSubmit={(values, onsubmitProp) => onSubmit(values, onsubmitProp)}
        validateOnMount={false}
      >
        {({ isSubmitting, isValid, setFieldValue, dirty }) => {
          return (
            <Form>
              <Grid container item gap={4}>
                <Grid item container justifyContent="center" gap={2}>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    md={12}
                    sm={10}
                    marginBottom="2rem"
                  >
                    <Typography variant="h6" className={classes.header}>
                      CREATE YOUR ACCOUNT
                    </Typography>
                  </Grid>

                  <Grid item container md={12} sm={10}>
                    <LoginInput
                      label="Email"
                      name="email"
                      type="email"
                      id="email"
                      placeholder="Enter your email"
                      hasStartIcon={false}
                    />
                  </Grid>
                  <Grid item container md={12} sm={10}>
                    <LoginInput
                      id="password"
                      label="Password"
                      name="password"
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      hasStartIcon={false}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() => setShowPassword((prev) => !prev)}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid item container md={12} sm={10}>
                    <FormikControl
                      control="select"
                      name="affiliation"
                      placeholder="Select affiliation"
                      label="Affiliation"
                      options={affiliationOptions}
                      onChange={async (value) => {
                        onAffiliationHandleChange(value, setFieldValue);
                      }}
                    />
                  </Grid>
                  {currentAffiliation === "HMO Doctor" ||
                  currentAffiliation === "Hospital Doctor" ? (
                    <Grid item container md={12} sm={10}>
                      <Autocomplete
                        id="providerId"
                        name="providerId"
                        loading={loading}
                        onInputChange={() => null}
                        onChange={(e, value) => {
                          setFieldValue("providerId", value?.value || "");
                        }}
                        options={organizationOptions}
                        getOptionLabel={(option) => option.key}
                        renderOption={(props, option) => (
                          <li {...props}>{option?.key}</li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              currentAffiliation === "Hospital Doctor"
                                ? "Select hospital"
                                : currentAffiliation === "HMO Doctor"
                                ? "Select HMO"
                                : ""
                            }
                          />
                        )}
                      />
                      <ErrorMessage name="providerId" component={TextError} />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item container md={12} sm={10}>
                  <FormikControl
                    control="input"
                    name="referralCode"
                    placeholder="Enter referral code"
                    label="Referral code (Optional)"
                  />
                </Grid>

                <Grid item container margin="auto" md={12} sm={10}>
                  <CustomButton
                    textColor="#fff"
                    variant="contained"
                    title="Create My Account"
                    type={greenButton}
                    className={classes.btn}
                    isSubmitting={isSubmitting}
                    disabled={!(dirty || isValid) || isSubmitting}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

SignUpForm.propTypes = {
  changeStep: PropTypes.func,
};

export default SignUpForm;
