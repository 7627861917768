import React from "react";
import useAlert from "./useAlert";
import { setAccessToken } from "accessToken";
import { useStepDispatch } from "context/stepContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createLogout, login } from "components/graphQL/Mutation";
import {
  getDoctorProfile,
  getProvider,
  getVerificationInfo,
} from "components/graphQL/UseQuery";
import { useData, useDataDispatch } from "context/dataContext";

export const useLogin = () => {
  const userData = useData();
  const [signInFunc] = useMutation(login);
  const stepDispatch = useStepDispatch();
  const dataDispatch = useDataDispatch();
  const [logoutUser] = useMutation(createLogout);
  const [fetchProvider] = useLazyQuery(getProvider);
  const { displayAlert, getErrorMsg } = useAlert();
  const [fetchProfile] = useLazyQuery(getDoctorProfile);
  const [fetchVerification] = useLazyQuery(getVerificationInfo);

  const showError = (error) => {
    console.error(error);
    const errMsg = getErrorMsg(error);
    displayAlert("error", errMsg);
  };

  const getDocProfile = async (dociId, email) => {
    try {
      const profileDataRes = await fetchProfile({
        variables: {
          dociId,
        },
      });

      const profileDataArr = profileDataRes?.data?.doctorProfiles.profile;

      const profileData = profileDataArr.filter(
        (profile) => profile?.email === email
      );

      return profileData;
    } catch (error) {
      showError(error);
    }
  };

  const checkVerificationStatus = async (id) => {
    await fetchVerification({
      variables: {
        profileId: id,
      },
    })
      .then((docVerificationRes) => {
        const verificationData =
          docVerificationRes.data.getVerifications.verification;
        if (verificationData.length === 0) {
          displayAlert(
            "error",
            "Verification not found or rejected. Please fill and submit the form below to create a verification process."
          );
          return stepDispatch({ type: "CHANGE_STEP", step: 3 });
        }

        if (verificationData[0].status === false) {
          return stepDispatch({ type: "CHANGE_STEP", step: 4 }); // VERIFICATION PENDING
        }

        if (verificationData[0].status === true) {
          destroyToken();
          return stepDispatch({ type: "CHANGE_STEP", step: 5 }); // VERIFICATION COMPLETED
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  const logOut = async (logoutUser, token) => {
    try {
      const res = await logoutUser({
        variables: {
          user: token,
        },
      });

      return res?.data?.logout?.result;
    } catch (error) {
      console.log("Error while trying to log out", error);
    }
  };

  const destroyToken = async () => {
    const id = localStorage.getItem("account_id");
    await logOut(logoutUser, id);
  };

  const checkProfile = async (dociId, email) => {
    await getDocProfile(dociId, email)
      .then((profileData) => {
        if (profileData.length === 0) {
          displayAlert(
            "error",
            "Profile not found. Please fill and submit the form below to create a profile"
          );
          stepDispatch({ type: "CHANGE_STEP", step: 2 });
        } else {
          localStorage.setItem("profile_id", profileData[0]._id);
          dataDispatch({ type: "UPDATE_DATA", data: profileData[0] });
          return checkVerificationStatus(profileData[0]._id);
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  const signIn = async (signInData, onsubmitProp) => {
    try {
      const { email, password } = signInData;
      await signInFunc({
        variables: {
          email: String(email.toLowerCase()),
          password,
        },
      })
        .then(async ({ data }) => {


          const { dociId, email, access_token, _id, role, providerId } =
            data?.login?.account;
            console.log(access_token,'data?.login?.account')
            localStorage.setItem("token", access_token);


          if (role !== "doctor") {
            displayAlert(
              "error",
              "This email is already linked to a Heala/HMO/Hospital user account."
            );
            stepDispatch({ type: "CHANGE_STEP", step: 1 });
          }

          const loginInfo = {
            email,
            account_id: _id,
            heala_id: dociId,
            token: access_token,
            providerId: providerId,
          };
          setAccessToken(access_token);
          Object.keys(loginInfo).map((key) =>
            localStorage.setItem(key, loginInfo[key])
          );
          dataDispatch({
            type: "UPDATE_DATA",
            data: { providerId: providerId },
          });

          if (!userData?.hospital) {
            const { data } = await fetchProvider({
              variables: { id:  String(providerId) },
            });
            dataDispatch({
              type: "UPDATE_DATA",
              data: { hospital: data?.getProvider?.name },
            });
          }

          return checkProfile(dociId, email);
        })
        .catch((error) => {
          showError(error);
        });
      onsubmitProp.resetForm();
    } catch (error) {
      showError(error);
    }
  };

  return { signIn, checkVerificationStatus, checkProfile };
};
