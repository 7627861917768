import React from "react";
import useAlert from "./useAlert";
import { useMutation } from "@apollo/client";
import { useStepDispatch } from "context/stepContext";
import { dateMoment } from "components/Utilities/Time";
import { createDoctorVerification } from "components/graphQL/Mutation";

const useSubmitCert = () => {
  const stepDispatch = useStepDispatch();
  const { displayAlert, getErrorMsg } = useAlert();
  const [createVerification] = useMutation(createDoctorVerification);

  const submitCert = async (values, onsubmitProp) => {
    const {
      degree,
      degreeImage,
      license,
      expire,
      licenseImage,
      licenseType,
      year,
      gYear,
      gImage,
      FacebookName,
      InstagramName,
      doctorName,
      referenceCode,
      doctorEmail,
      doctorPosition,
      doctorInstitution,
    } = values;

    const gradYear = dateMoment(gYear);
    const expires = dateMoment(expire);
    const mdYear = dateMoment(year);

    try {
      const { data } = await createVerification({
        variables: {
          degree,
          image: degreeImage,
          year: mdYear,
          number: license,
          expiryDate: expires,
          licenseImage,
          type: licenseType,
          graduation: gradYear == null ? '': gradYear , //
          graduationImage: gImage,
          facebook: FacebookName,
          instagram: InstagramName,
          doctorName: doctorName,
          reference: referenceCode,
          profileId: localStorage.getItem("profile_id"),
          doctorEmail,
          doctorPosition,
          doctorInstitution,
        },
      });
      if (data) {
        onsubmitProp.resetForm();
        stepDispatch({ type: "CHANGE_STEP", step: 4 });
      } else {
        return { isSuccessful: false };
      }
    } catch (err) {
      console.error(err);
      const errMsg = getErrorMsg(err);
      displayAlert("error", errMsg);
    }
  };
  return { submitCert };
};

export default useSubmitCert;
