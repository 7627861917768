import { gql } from "@apollo/client";

export const getUserTypes = gql`
  query getUserTypeProviders($userTypeId: String) {
    getUserTypeProviders(filterBy: { userTypeId: $userTypeId }) {
      provider {
        _id
        name
        icon
        userTypeId {
          _id
          name
          icon
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const dashboard = gql`
  query getStats($providerId: String, $q: String) {
    getStats(filterBy: { providerId: $providerId }, q: $q) {
      patientStats
      doctorStats
      totalEarnings
      totalPayout
      appointmentStats
      subscribers
      availabilityCalendar {
        _id
        doctor
        doctorData
        dates {
          day
          available
          times {
            start
            stop
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const getDoctorProfile = gql`
query doctorProfiles($dociId: String) {
  doctorProfiles(
    filterBy: { dociId: $dociId }
    orderBy: "-createdAt"
    page: 1
  ) {
    #.. filterBy, orderBy and page are optional filters
    profile {
      _id
      firstName
      lastName
      gender
      phoneNumber
      createdAt
      updatedAt
      email
      email
      hospital
      specialization
      dob
      cadre
      picture
      providerId{
        _id
        name
      }
      #consultations
      status
      dociId
      rating
      accountDetails {
        accountName
        accountNumber
        bankName
      }
    }
    pageInfo {
      totalDocs
      limit
      offset
      hasPrevPage
      hasNextPage
      page
      totalPages
      pagingCounter
      prevPage
      nextPage
    }
  }
}
`;

export const getVerificationInfo = gql`
query getVerifications($profileId: String) {
  getVerifications(filterBy: { profileId: $profileId }) {
    verification {
      _id
      qualification
      license
      yearbook
      alumni_association
      reference
      external_reference
      status
      createdAt
      updatedAt
      profileId
    }
    pageInfo {
      totalDocs
      limit
      offset
      hasPrevPage
      hasNextPage
      page
      totalPages
      pagingCounter
      prevPage
      nextPage
    }
  }
}
`;

export const getPartners = gql`
query getPartners($category: String) {
  getPartners(
    filterBy: { category: $category }
    orderBy: "-createdAt"
    page: 1
  ) {
    data {
      _id
      name
      email
      category
      logoImageUrl
      profileUrl
      providerId
    }
    pageInfo {
      totalDocs
      limit
      offset
      hasPrevPage
      hasNextPage
      page
      totalPages
      pagingCounter
      prevPage
      nextPage
    }
  }
}
`;

export const getProviders = gql`
query getProviders(
  $userTypeId: String!
  $page: Int!
  $orderBy: String!
) {
  getProviders(
    filterBy: { userTypeId: $userTypeId }
    page: $page
    orderBy: $orderBy
  ) {
    provider {
      _id
      name
      icon
      iconAlt
      userTypeId {
        _id
        name
        icon
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      profileUrl
      userCount
      doctorCount
      enrolleeCount
      partnerCount
    }
    pageInfo {
      totalDocs
      limit
      offset
      hasPrevPage
      hasNextPage
      page
      totalPages
      pagingCounter
      prevPage
      nextPage
    }
  }
}
`;

export const fetchUserTypes = gql`
query getUserTypes {
  getUserTypes {
    userType {
      _id
      name
      icon
      description
      createdAt
      updatedAt
    }
    pageInfo {
      totalDocs
      limit
      offset
      hasPrevPage
      hasNextPage
      page
      totalPages
      pagingCounter
      prevPage
      nextPage
    }
  }
}

`;

export const getProvider = gql`
query getProvider($id: String!) {
  getProvider(id: $id) {
    _id
    name
    icon
    iconAlt
    userTypeId{
      _id
    }
    createdAt
    updatedAt
  }
}
`;
