import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useQuery } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import { Grid, FormLabel, Typography } from "@mui/material";

import { useStyles } from "styles/formStyles";
import DragAndDrop from "components/DragAndDrop";
import { CustomButton } from "components/Utilities";
import { getUserTypes } from "components/graphQL/UseQuery";
import { FormValidationSchema } from "helpers/formValidation";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import FormikControl from "components/validation/FormikControl";
import { ReactComponent as ReferenceIcon } from "assets/images/reference.svg";
import {
  checkForIncompleteFields,
  getQulificationsOptions,
  getSelectedCertification,
} from "helpers/helperFuncs";
import {
  requirementValues,
  step3FromInitialValues,
} from "../../helpers/mockData";
import VerificationCard from "components/cards/VerificationCard";
import FormContainer from "components/layouts/FormContainer";
import HealaIcon from "components/icons/HealaIcon";
import AluminiSvg from "assets/images/alumni-icon.svg";
import YearbookIcon from "assets/images/yearbook-icon.svg";
import QualificationIcon from "assets/images/qualification-icon.svg";
import licenseIcon from "assets/images/license-icon.svg";
import { BackBtn } from "components/Utilities/CustomButton";
import { useStepDispatch } from "context/stepContext";
import { useData } from "context/dataContext";
import useAlert from "hooks/useAlert";
import useSubmitCert from "hooks/useSubmitCert";

const SectionHeader = ({ text }) => {
  return (
    <Typography
      sx={{
        color: "#3E5EA9",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "31.06px",
      }}
    >
      {text}
    </Typography>
  );
};

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

const Forms = () => {
  const data = useData();
  const classes = useStyles();
  const theme = useTheme();
  const { submitCert } = useSubmitCert();
  const stepDispatch = useStepDispatch();
  const { displayAlert, getErrorMsg } = useAlert();
  const [dateNow, setDateNow] = React.useState("");
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setDateNow(Date.now());
  }, []);

  const greenButton = {
    background: theme.palette.success.main,
    hover: theme.palette.success.light,
    active: theme.palette.primary.dark,
  };

  const options = [{ key: "MDCN", value: "MDCN" }];

  const [qualification] = useState(true);
  const [license] = useState(true);
  const [yearBook, setYearBook] = useState(false);
  const [alumni, setAlumni] = useState(false);
  const [reference, setReference] = useState(false);
  const [externalReference, setExternalReference] = useState(false);
  const [dropDown, setDropDown] = useState([]);
  const [selectedCert, setSelectedCert] = useState([]);

  React.useEffect(() => {
    const selectedCertifications = getSelectedCertification({
      qualification: qualification,
      license: license,
      yearBook: yearBook,
      alumni: alumni,
      reference: reference,
      externalReference: externalReference,
    });
    setSelectedCert(selectedCertifications);
  }, [qualification, license, yearBook, alumni, reference, externalReference]);

  const userTypeId = "61ed231de6091400135e3d8a"

  const { data: da } = useQuery(getUserTypes, {
    variables: {
      userTypeId: userTypeId,
    },
  });

  useEffect(() => {
    if (da) {
      const datas = da.getUserTypeProviders.provider;
      setDropDown(
        datas &&
          datas.map((i) => {
            return { key: i.name, value: i._id };
          })
      );
    }
  }, [da]);

  const qualificationOptions = getQulificationsOptions(data?.cadre);

  const onFormSubmit = async (values, onsubmitProp) => {
    try {
      if (selectedCert.length === 0) {
        displayAlert(
          "error",
          "Select at least 2 verification methods and fill the forms below"
        );
        return;
      }

      const incompleteFields = checkForIncompleteFields(
        selectedCert,
        values,
        requirementValues
      );
      if (!incompleteFields) {
        displayAlert("error", "Empty fields detected, complete the form.");
        return;
      }

      if (!checked) {
        displayAlert(
          "error",
          "To continue, click on the box to accept our terms and conditiion."
        );
        return;
      }

      return submitCert(values, onsubmitProp);
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(errMsg);
      displayAlert("error", errMsg);
    }
  };

  return (
    <Grid container gap={1} sx={{ width: "944px" }}>
      <FormContainer>
        <Grid
          item
          container
          justifyContent="center"
          direction="column"
          gap={5}
          sx={{ padding: { lg: "3rem 80px" } }}
        >
          <Grid item>
            <Typography
              textAlign="center"
              sx={{ fontWeight: 600, fontSize: "30px", color: "#2D2F39" }}
            >
              Doctor Verification
            </Typography>
            <Typography
              textAlign="center"
              style={{
                lineHeight: "27px",
                color: "#757886",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              Upload at least 2 verification method.
            </Typography>
            <Typography
              textAlign="center"
              sx={{
                color: "#3E5EA9",
                lineHeight: "27px",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              (License & Qualification mandatory and any other)
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            rowGap={5}
            sx={{ margin: "auto", flexWrap: "wrap" }}
          >
            {[
              {
                id: 1,
                name: license,
                setName: () => null,
                title: "Medical License",
                iconBgColor: "#DBEEE8",
                icon: <img src={licenseIcon} alt="License icon" />,
              },
              {
                id: 2,
                name: qualification,
                setName: () => null,
                title: "Qualification",
                iconBgColor: "rgba(62, 165, 132, 0.1)",
                icon: <img src={QualificationIcon} alt="qualification icon" />,
              },
              {
                id: 3,
                name: yearBook,
                setName: setYearBook,
                title: "Year Book",
                iconBgColor: "rgba(207, 129, 81, 0.1)",
                icon: <img src={YearbookIcon} alt="Yearbook icon" />,
              },
              {
                id: 4,
                name: alumni,
                setName: setAlumni,
                title: "Alumni Association",
                iconBgColor: "#FFEDF2",
                icon: <img src={AluminiSvg} alt="Alumini icon" />,
              },
              {
                id: 5,
                name: reference,
                setName: setReference,
                title: "Heala Partner",
                iconBgColor: "#F8F8F8",
                icon: <HealaIcon sx={{ width: "76px", height: "29px" }} />,
              },
              {
                id: 6,
                name: externalReference,
                setName: setExternalReference,
                title: "External Reference",
                iconBgColor: "#ECFAFD",
                icon: (
                  <ReferenceIcon color="error" className={classes.cardIcon} />
                ),
              },
            ].map((card, idx) => {
              return (
                <VerificationCard
                  key={idx}
                  name={card.name}
                  setName={card.setName}
                  title={card.title}
                  icon={card.icon}
                  iconBgColor={card.iconBgColor}
                />
              );
            })}
          </Grid>

          {selectedCert.length > 0 ? (
            ""
          ) : (
            <Grid item className={classes.infoBadge} gap={2}>
              <ErrorRoundedIcon />
              <Typography variant="h6">
                Please select at least two verification methods (Medical licence
                and any other verification method).
              </Typography>
            </Grid>
          )}

          {/* ========= FORM SECTION ========= */}
          <Grid item>
            <Formik
              initialValues={step3FromInitialValues}
              onSubmit={(values, onsubmitProp) =>
                onFormSubmit(values, onsubmitProp)
              }
              validationSchema={FormValidationSchema}
              validateOnChange={true}
              validateOnMount={false}
              validateOnBlur={true}
            >
              {({ setValues, setFieldValue, isSubmitting, dirty, isValid }) => {
                return (
                  <Form>
                    {/* ========= LICENSE FORM ========= */}
                    {license ? (
                      <>
                        <Grid
                          item
                          container
                          md={12}
                          sm={12}
                          direction="column"
                          justifyContent="space-between"
                          gap={3}
                          marginBottom={4}
                        >
                          <SectionHeader text="Medical License (MDCN)" />
                          <Grid
                            item
                            container
                            justifyContent="space-between"
                            spacing={3}
                          >
                            <Grid item container md={12} sm={10}>
                              <FormikControl
                                control="input"
                                name="license"
                                placeholder="Enter license number"
                                label="License Number"
                              />
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="space-between"
                              gap={2}
                            >
                              <Grid item container md={5} sm={10}>
                                <FormikControl
                                  control="select"
                                  name="licenseType"
                                  placeholder="Select License Type"
                                  label="Type"
                                  options={options}
                                />
                              </Grid>
                              <Grid item container md={5} sm={10}>
                                <FormikControl
                                  control="date"
                                  name="expire"
                                  label="Expiry Date"
                                  setFieldValue={setFieldValue}
                                  setValues={setValues}
                                  startDate={dateNow}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                          >
                            Upload Your License
                          </FormLabel>
                          <DragAndDrop
                            name="licenseImage"
                            setFieldValue={setFieldValue}
                            maxFiles={1}
                          />
                        </Grid>
                      </>
                    ) : null}

                    {/* ========= MBBS QUALIFICATION FORM ========= */}
                    {qualification ? (
                      <>
                        <Grid
                          item
                          container
                          md={12}
                          sm={12}
                          direction="column"
                          justifyContent="space-between"
                          gap={1}
                          marginBottom={4}
                        >
                          <SectionHeader text="Qualification" />

                          <Grid
                            item
                            container
                            justifyContent="space-between"
                            gap={1}
                          >
                            <Grid item container md={5} sm={10}>
                              <FormikControl
                                control="select"
                                name="degree"
                                placeholder="Select degree"
                                options={qualificationOptions}
                                label="Degree"
                              />
                            </Grid>
                            <Grid item container md={5} sm={10}>
                              <FormikControl
                                control="date"
                                name="year"
                                label="Year"
                                setFieldValue={setFieldValue}
                                setValues={setValues}
                                endDate={dateNow}
                              />
                            </Grid>
                          </Grid>
                          <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                          >
                            Upload Your Qualification
                          </FormLabel>
                          <DragAndDrop
                            name="degreeImage"
                            setFieldValue={setFieldValue}
                            maxFiles={1}
                          />
                        </Grid>
                      </>
                    ) : null}

                    {/* ========= YEARBOOK FORM ========= */}
                    {yearBook ? (
                      <>
                        <Grid
                          item
                          container
                          md={12}
                          xs={12}
                          direction="column"
                          justifyContent="space-between"
                          gap={3}
                          marginBottom={4}
                        >
                          <SectionHeader text="Year Book" />

                          <Grid
                            item
                            container
                            justifyContent="space-between"
                            spacing={3}
                          >
                            <Grid item container justifyContent="start">
                              <FormikControl
                                control="date"
                                name="gYear"
                                label="Graduation year"
                                setFieldValue={setFieldValue}
                                setValues={setValues}
                                endDate={dateNow}
                              />
                            </Grid>
                          </Grid>
                          <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                          >
                            Upload Your Yearbook
                          </FormLabel>
                          <DragAndDrop
                            name="gImage"
                            setFieldValue={setFieldValue}
                            maxFiles={1}
                          />
                        </Grid>
                      </>
                    ) : null}

                    {/* Alumni Association */}
                    {alumni ? (
                      <>
                        <Grid
                          item
                          container
                          md={12}
                          sm={12}
                          direction="column"
                          justifyContent="space-between"
                          gap={3}
                          marginBottom={4}
                        >
                          <SectionHeader text="Alumni Association" />

                          <Grid
                            item
                            container
                            justifyContent="space-between"
                            gap={3}
                          >
                            <Grid item container sm={10} md={12}>
                              <FormikControl
                                control="input"
                                name="FacebookName"
                                placeholder="Enter facebook group name"
                                label="Facebook Group Name"
                              />
                            </Grid>
                            <Grid item container sm={10} md={12}>
                              <FormikControl
                                control="input"
                                name="InstagramName"
                                placeholder="Enter group instagram handle"
                                label="Instagram Handle"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    {/* Heala refrence */}
                    {reference ? (
                      <>
                        <Grid
                          item
                          container
                          md={12}
                          sm={12}
                          direction="column"
                          justifyContent="space-between"
                          gap={3}
                          marginBottom={4}
                        >
                          <SectionHeader text="Heala partner" />

                          <Grid
                            item
                            container
                            justifyContent="space-between"
                            spacing={3}
                          >
                            <Grid item container md={12} sm={10}>
                              <FormikControl
                                control="select"
                                name="referenceCode"
                                placeholder="Select reference "
                                label="Partner hospital"
                                options={dropDown}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}

                    {/* external reference */}
                    {externalReference ? (
                      <>
                        <Grid
                          item
                          container
                          md={12}
                          sm={12}
                          direction="column"
                          justifyContent="space-between"
                          gap={2}
                          marginBottom={4}
                        >
                          <SectionHeader text="External Reference" />

                          <Grid item md={12}>
                            <Grid
                              item
                              container
                              marginBottom={2}
                              justifyContent="space-between"
                            >
                              <Grid item md={5} sm={10}>
                                <FormikControl
                                  control="input"
                                  name="doctorName"
                                  placeholder="Enter Doctor's name"
                                  label="Doctor's name"
                                />
                              </Grid>
                              <Grid item md={5} sm={10}>
                                <FormikControl
                                  control="input"
                                  name="doctorInstitution"
                                  label="Doctor's institution"
                                  placeholder="e.g Federal Teaching hospital Akure"
                                />
                              </Grid>
                            </Grid>
                            <Grid item container justifyContent="space-between">
                              <Grid item container md={5} sm={10}>
                                <FormikControl
                                  control="input"
                                  name="doctorPosition"
                                  label="Doctor's Position"
                                  placeholder="e.g Dentist"
                                />
                              </Grid>
                              <Grid item container md={5} sm={10}>
                                <FormikControl
                                  control="input"
                                  name="doctorEmail"
                                  label=" Doctor's Email"
                                  placeholder="Enter email"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}

                    <Grid
                      sx={{
                        marginBottom: "2rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        color="success"
                        size="large"
                        sx={{ marginRight: "0.5rem" }}
                      />
                      <Typography>
                        By clicking the "Save Record" button, you state that you
                        have read, understood and agree to our{" "}
                        <a
                          href="https://heala.ng/terms/#imp"
                          rel="no-referrer"
                          target="_blank"
                        >
                          Terms and Conditions
                        </a>
                      </Typography>
                    </Grid>

                    <Grid item container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <BackBtn
                          onClick={() =>
                            stepDispatch({ type: "CHANGE_STEP", step: 2 })
                          }
                        />
                      </Grid>
                      <Grid item>
                        <CustomButton
                          variant="contained"
                          title="Submit Records"
                          height="60px"
                          type={greenButton}
                          className={classes.btn}
                          isSubmitting={isSubmitting}
                          disabled={!dirty || !isValid || isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </FormContainer>
    </Grid>
  );
};

export default Forms;
Forms.propTypes = {
  handleNext: PropTypes.func.isRequired,
};
